import './App.css';
import Nav from './components/Nav';
import Mint from './components/Mint';
import {useState} from 'react';


function App() {
  const [accounts, setAccounts] = useState([]);

  return (
    <div className='app'>
      <Nav accounts={accounts} setAccounts={setAccounts}/>
      <div className='main-container'>
        <div className='mint'>
          <Mint accounts={accounts} setAccounts={setAccounts}/>    
        </div>   
        <div class="space stars1"></div>
        <div class="space stars2"></div>
        <div class="space stars3"></div>
      </div>
    </div>
  );
}

export default App;
