import {useState, useEffect} from 'react';
import {ethers, BigNumber} from 'ethers';
import KingDoge from '../KingDoge.json';
import './Mint.css';

const KingDogeAddress="0x31DAeff1f85b431d3FA112ca4b81FfdE92645bF0";

const Mint = ({accounts, setAccounts}) => {
    const [mintAmount, setMintAmount] = useState(1);
    const isConnected = Boolean(accounts[0]);
    const [totalSupply, setTotalSupply] = useState(0);

    useEffect(()=>{
        showSupply();
      });

    const handleMint = async () => {
        if (window.ethereum){
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(
                KingDogeAddress,
                KingDoge.abi,
                signer
            );
            try{
                let owedAmount = await contract.getOwedAmount(BigNumber.from(mintAmount));
                owedAmount=BigNumber.from(owedAmount);
                console.log(owedAmount);
                const response = await contract.mintNft(BigNumber.from(mintAmount), {value : owedAmount});
                console.log('response: ', response);
            } catch (err){
                console.log("error", err);
            }
        }
    }

    const showSupply = async () => {
        var totalSupply;
        if (window.ethereum){
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(
                KingDogeAddress,
                KingDoge.abi,
                signer
            );
            try{
                totalSupply = await contract.totalSupply();
                setTotalSupply(String(totalSupply));
            } catch (err){
                console.log("error", err);
            }
        }

    }

    const handleDecrement = () => {
        if (mintAmount <= 1) return;
        setMintAmount(mintAmount-1);
    }

    const handleIncrement = () => {
        if (mintAmount >= 20) return;
        setMintAmount(mintAmount+1);
    }


    return(
        <div>
            <h1>King Doge</h1>
            {isConnected ? (
                <div>
                    <div>
                        <button onClick={handleDecrement}>-</button>
                        <input className='mint-num' disabled='true' type="number" value={mintAmount} />
                        <button onClick={handleIncrement}>+</button>
                    </div>
                    <button className='mint-button' onClick={handleMint}>Mint Now</button>
                    <div className="supply">
                        {totalSupply}/3250
                    </div>
                </div>
            ) : (
                <p> please connect your wallet to mint </p>
            )}
        </div>
    )

    
}

export default Mint;