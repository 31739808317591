import React from 'react';
import './Nav.css';
import { AiFillTwitterCircle } from "react-icons/ai";
import '../App.css';
import { Box, Button, Flex, Image, Link, Spacer} from '@chakra-ui/react';


const Nav = ({accounts, setAccounts}) =>{
    const isConnected = Boolean(accounts[0]);

    async function connectAccount() {
        if (window.ethereum){
            const accounts = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            setAccounts(accounts);
        }
    }

    return(
        <div className='nav-bar'>
            <div className='nav-right'>
                <Link  href='https://twitter.com/_KingDoge_'>
                    <AiFillTwitterCircle className='twitter' size='4rem'/>
                </Link>
                {isConnected ? (
                    <p>Connected</p>
                ) : (
                    <button onClick={connectAccount} className='glow-on-hover connect'>Connect Wallet</button>
                )}
            </div>
        </div>
    );
}

export default Nav;